import barba from '@barba/core';
import { gsap } from "gsap/all";

// import Header from "./header";
import NavMobile from "./nav-mobile";
// import Reveal from "./reveal";

export default class BarbaJs {

  constructor() {
    const body = document.querySelector('body');
    const mask = document.querySelector('.barba-mask');
    const navigationLinks = document.querySelectorAll('.navigation--header-link');


    barba.init({
      timeout: 5000,

      // debug: true,
      // cacheIgnore: true,
      // prefetchIgnore: true,

      // requestError: (trigger, action, url, response) => {
      //   console.log({
      //     trigger: trigger,
      //     action: action,
      //     url: url,
      //     response: response,
      //   })
      // },

      prevent: ({ el }) => el.classList && (el.classList.contains('prevent-barba') || el.classList.contains('active')),

      transitions: [
        {
          name: 'default-transition',

          once(data) {
            setTimeout(() => {
              // new Header();
              new NavMobile();
              // new Slider('.js--slider');
              // new Reveal('.js--reveal');
              // refreshFsLightbox();
            }, 1);

            return gsap.to(body, {
              opacity: 1,
              duration: 1,
              delay: 0.25,
            });
          },

          beforeLeave(data) {
            mask.classList.replace('opacity-0', 'opacity-100');
            mask.classList.replace('invisible', 'visible');

            return gsap.to(data.current.container, {
              opacity: 0,
              duration: 0.5,
              delay: data.trigger.classList != undefined && data.trigger.classList.contains('navigation--header-link') ? 1 : 0.4,
            });
          },

          afterLeave(data) {
            window.scrollTo({
              top: 0,
              left: 0,
            });

            // update body classes
            body.classList.toggle('is-home', data.next.namespace === 'home');
            body.classList.toggle('not-home', data.next.namespace !== 'home');
          },

          beforeEnter(data) {
            navigationLinks.forEach(navigationLink => {
              navigationLink.classList.toggle('decoration-white', navigationLink.dataset.slug === data.next.namespace);
              navigationLink.classList.toggle('lg:decoration-theme-navigation', navigationLink.dataset.slug === data.next.namespace);
            });

            data.next.container.style.opacity = 0;
          },

          enter(data) {
            data.current.container.remove();
            // reinit JS
            setTimeout(() => {
              // new Header();
              new NavMobile();
              // new Slider('.js--slider');
              // new Reveal('.js--reveal');
              // refreshFsLightbox();
            }, 1);

          },

          afterEnter(data) {

            return gsap.to(data.next.container, {
              opacity: 1,
              duration: 0.1,
              delay: 1,
              // onStart: () => {
              // },
              onComplete: () => {
                mask.classList.replace('opacity-100', 'opacity-0');
                mask.classList.replace('visible', 'invisible');
              }
            });
          }

        },
      ],

    });
  }

}
